

// // 先加载所有的主题(default在main.scss中加载，因为default主题对body没有使用class，可以应用在登陆页上)
import './dark/styls.scss';
import './default/style.scss';

// 默认主题
export const defaultTheme = 'light';

export const changeTheme = (themeName = defaultTheme) => {
  let key = 'themeProperty';
  if (themeName === 'light') {
    // 移除之前主题的属性
    window.localStorage.removeItem(key)
    if (document.body.className) document.body.className = 'light'
  } else {
    // 把该主题的所有属性存到缓存
    document.body.className = themeName;
  }
}
